export const sfx = {
  lock_screen_tap: require('assets/sounds/sfx/KEYPAD_BUTTON_4.mp3').default,
  yellow_btn: require('assets/sounds/sfx/GENERAL_TAP_3.mp3').default,
  correct_password: require('assets/sounds/sfx/UNLOCK_5.mp3').default,
  notification: require('assets/sounds/sfx/MESSAGE_5.mp3').default,
  case1_open: require('assets/sounds/sfx/CASE_SELECT.mp3').default,
  phone_call: require('assets/sounds/sfx/RINGTONE_RVSD_1.mp3').default,
  success_screen: require('assets/sounds/sfx/CONGRATS_PAGE_APPEARING_TONAL_2.mp3').default,
  uv_light: require('assets/sounds/sfx/UV_LIGHT.mp3').default,
  paper_shredder: require('assets/sounds/sfx/PAPER_SHREDDER.mp3').default,
  countdown: require('assets/sounds/sfx/countdown_trim.mp3').default,
  page1: require('assets/sounds/sfx/trim-page-turn-1.mp3').default,
  page2: require('assets/sounds/sfx/trim-page-turn-2.mp3').default,
  page3: require('assets/sounds/sfx/trim-page-turn-3.mp3').default,
  page4: require('assets/sounds/sfx/trim-page-turn-4.mp3').default,
  page5: require('assets/sounds/sfx/trim-page-turn-5.mp3').default,
  badge: require('assets/sounds/sfx/badge.mp3').default,
  tv_ambient: require('assets/sounds/sfx/tv_ambient_g3.mp3').default,
  tv_on: require('assets/sounds/sfx/tv_on_g3.mp3').default,
  uv_scanner: require('assets/sounds/sfx/uv_scanner.mp3').default,
};
