import { px } from './mixins';

export const basePadding = 50;

// ====================================================================
// Base palette - colors for use across the site.
//
// Use https://chir.ag/projects/name-that-color when adding a new color
// ====================================================================
export const colors = {
  white: '#fff',
  whitef6: '#fffef6',
  graye5: '#e5e5e5',
  graye7: '#eeede7',
  carrara: '#edece6',
  cararra: '#efeee8',
  vistaWhite: '#e3ded7',
  silver: '#c4c4c4',
  tide: '#bcb8b5',
  delta: '#b1b1a9',
  deltaDark: '#a6a6a2',
  rollingStone: '#74777b',
  chicago: '#5B5755',
  mako: '#424850',
  outerSpace: '#2e3338',
  shark: '#1a1f25',
  blackBeluga: '#010407',
  blackPearl: '#010509',
  blackChocolate: '#0c0d30',
  blackCarbon: '#0C0D30',
  black: '#000',
  yellow: '#fff000',
  red: '#ff0e0e',
  callRed: '#f73d39',
  vividRed: '#FE0000',
  guardsmanRed: '#BE0000',
  callGreen: '#53d76a',
  spray: '#7eede4',
  aqua: '#00e4fe',
  uv: '#00e4fe', // same as aqua
  darkTorquoise: '#10CDE8',
  blue: '#0b65da',
  darkCerulean: '#055684',
  darkBlue: '#0d1d33',
  deepBlue: '#0c182c',
  lightGreen: '#12737e',
  quizRedText: '#FF8C8C',
  quizRedBackground: '#FF0000',
  quizGreenText: '#C9FED7',
  quizGreenBackground: '#00ff19',
  tapa: '#73736f',
  codGray: '#121011',
  alabaster: '#fafafa',
};

// ====================================================================
// Eases
// ====================================================================
export const ease = {
  in: 'cubic-bezier(0.4, 0, 1, 1)',
  out: 'cubic-bezier(0, 0, 0.2, 1)',
  inOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
  inSine: 'cubic-bezier(0.12, 0, 0.39, 0)',
  outSine: 'cubic-bezier(0.61, 1, 0.88, 1)',
  inOutSine: 'cubic-bezier(0.37, 0, 0.63, 1)',
  inQuad: 'cubic-bezier(0.5, 0, 0.75, 0)',
  outQuad: 'cubic-bezier(0.25, 1, 0.5, 1)',
  inOutQuad: 'cubic-bezier(0.76, 0, 0.24, 1)',
  inCubic: 'cubic-bezier(0.32, 0, 0.67, 0)',
  outCubic: 'cubic-bezier(0.33, 1, 0.68, 1)',
  inOutCubic: 'cubic-bezier(0.65, 0, 0.35, 1)',
  inQuint: 'cubic-bezier(0.64, 0, 0.78, 0)',
  outQuint: 'cubic-bezier(0.22, 1, 0.36, 1)',
  inOutQuint: 'cubic-bezier(0.83, 0, 0.17, 1)',
  inExpo: 'cubic-bezier(0.7, 0, 0.84, 0)',
  outExpo: 'cubic-bezier(0.16, 1, 0.3, 1)',
  inOutExpo: 'cubic-bezier(0.87, 0, 0.13, 1)',
  inCirc: 'cubic-bezier(0.55, 0, 1, 0.45)',
  outCirc: 'cubic-bezier(0, 0.55, 0.45, 1)',
  inOutCirc: 'cubic-bezier(0.85, 0, 0.15, 1)',
  inBack: 'cubic-bezier(0.36, 0, 0.66, -0.56)',
  outBack: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
  inOutBack: 'cubic-bezier(0.68, -0.6, 0.32, 1.6)',
};

export const framerEase = {
  in: [0.4, 0, 1, 1],
  out: [0, 0, 0.2, 1],
  inOut: [0.4, 0, 0.2, 1],
  inSine: [0.12, 0, 0.39, 0],
  outSine: [0.61, 1, 0.88, 1],
  inOutSine: [0.37, 0, 0.63, 1],
  inQuad: [0.5, 0, 0.75, 0],
  outQuad: [0.25, 1, 0.5, 1],
  inOutQuad: [0.76, 0, 0.24, 1],
  inCubic: [0.32, 0, 0.67, 0],
  outCubic: [0.33, 1, 0.68, 1],
  inOutCubic: [0.65, 0, 0.35, 1],
  inQuint: [0.64, 0, 0.78, 0],
  outQuint: [0.22, 1, 0.36, 1],
  inOutQuint: [0.83, 0, 0.17, 1],
  inExpo: [0.7, 0, 0.84, 0],
  outExpo: [0.16, 1, 0.3, 1],
  inOutExpo: [0.87, 0, 0.13, 1],
  inCirc: [0.55, 0, 1, 0.45],
  outCirc: [0, 0.55, 0.45, 1],
  inOutCirc: [0.85, 0, 0.15, 1],
  inBack: [0.36, 0, 0.66, -0.56],
  outBack: [0.34, 1.56, 0.64, 1],
  inOutBack: [0.68, -0.6, 0.32, 1.6],
};
