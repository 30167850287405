import styled from 'styled-components';
import { box, px } from 'styles/mixins';
import { colors } from 'styles/vars';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${colors.blackPearl};

  &.light {
    color: ${colors.white};

    > .logo {
      background-image: url(${require('assets/images/logo-white.png')});
    }
  }

  > .four {
    ${box(px(20), px(28))}
  }

  > .bar {
    ${box(px(1), px(28))}
    background: currentColor;
    margin: 0 ${px(8)} 0 ${px(12)};
    transform: rotate(11.69deg);
  }

  > .logo {
    ${box(px(75), px(34))}
    background-image: url(${require('assets/images/logo-black.png')});
    background-size: contain;
  }
`;
