import styled from 'styled-components';

import { colors } from 'styles/vars';
import { box, flexCenter, px } from 'styles/mixins';
import { typography20Caps, typography24Caps } from 'styles/typography';

export const Wrapper = styled.button`
  ${flexCenter()}
  background-color: ${colors.yellow};
  background-image: url(${require('assets/images/cta-noise.png')});
  background-size: cover;
  color: ${colors.black};
  min-height: ${px(40)};
  min-width: ${px(190)};
  max-width: 100%;
  padding: ${px(10)} ${px(20)};
  box-shadow: ${px(10)} ${px(15)} ${px(20)} rgba(0, 0, 0, 0.13);

  &.big {
    min-height: ${px(60)};
    min-width: ${px(340)};
    box-shadow: 0 ${px(10)} ${px(20)} rgba(0, 0, 0, 0.1);

    > .label {
      ${typography24Caps}
    }
  }

  > .label {
    ${typography20Caps}
    text-align: center;
  }
`;
