import { css } from 'styled-components';

type CSSPosition = 'static' | 'absolute' | 'fixed' | 'relative' | 'sticky';

export const vh = (value: number) => {
  return `calc(var(--vh) * ${value})`;
};

export const px = (value: number) => {
  return `${value * 0.1}rem`;
};

export const zIndex = (id: 'transition' | 'banner' | 'modal' | 'noscript' | 'rotate') => {
  const order = ['transition', 'banner', 'modal', 'noscript', 'rotate'];
  return css`
    z-index: ${order.indexOf(id)};
  `;
};

export const box = (width, height = undefined) => {
  return css`
    width: ${width};
    height: ${height || width};
  `;
};

export const rect = (position: CSSPosition, top, left, width, height = 'auto') => {
  return css`
    position: ${position};
    top: ${top};
    left: ${left};
    ${box(width, height)}
  `;
};

export const absoluteCenter = (extraTransform = '') => {
  return css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) ${extraTransform};
  `;
};

export const fixedCenter = (extraTransform = '') => {
  return css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) ${extraTransform};
  `;
};

export const flexCenter = (direction = 'row') => {
  return css`
    display: flex;
    align-items: center;
    flex-direction: ${direction};
    justify-content: center;
  `;
};

export const autoAlpha = (value, time = 0, easing = 'linear') => {
  return css`
    opacity: ${value};
    visibility: ${value ? 'visible' : 'hidden'};
    ${time ? `transition: opacity ${time}s ${easing}, visibility ${time}s ${easing};` : ''}
  `;
};

export const defaultOutline = () => {
  return css`
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  `;
};

export const uvBase = (bgBrightness = 0.5, bgGrayscale = 1, baseTextureOpacity = 0.4) => {
  return css`
    ${rect('absolute', 0, 0, '100%', '100%')}
    backdrop-filter: brightness(${bgBrightness}) grayscale(${bgGrayscale});

    &::before {
      ${rect('absolute', 0, 0, '100%', '100%')}
      background: url(${require('assets/images/uv-base.jpg')});
      background-size: cover;
      background-position: center center;
      opacity: ${baseTextureOpacity};
      content: '';
    }

    > * {
      mix-blend-mode: hard-light;
    }
  `;
};

export const gameOneZIndex = (id: string) => {
  const order = ['base', 'gradient', 'back', 'uv', 'picture', 'shatter', 'video'];
  return css`
    z-index: ${order.indexOf(id)};
  `;
};

export const fullHeight = () => css`
  height: ${vh(100)};
  transition: height 200ms;
`;
