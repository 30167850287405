import React, { forwardRef, memo } from 'react';
import classNames from 'classnames';

import * as Styled from './CtaYellow.styled';

import { sfx } from 'constants/assets';

import AudioManager from 'services/audiomanager.service';

interface Props {
  className?: string;
  onClick?: () => void;
  label: string;
  big?: boolean;
}

export const CtaYellow = forwardRef<HTMLButtonElement, Props>(({ className, label, onClick, big = false }, ref) => {
  const clickHandler = () => {
    if (!onClick) return;
    AudioManager.instance.playSound(sfx.yellow_btn);
    onClick();
  };
  return (
    <Styled.Wrapper className={classNames(className, { big })} onClick={clickHandler} ref={ref}>
      <span className="label">{label}</span>
    </Styled.Wrapper>
  );
});

export default memo(CtaYellow);
