import { css } from 'styled-components';

import { px } from 'styles/mixins';

export const letterSpacing = (size: number, letter: string | number) => {
  if (typeof letter === 'string') return letter;
  return `${letter / size}em`;
};

export const font = (
  family: string,
  size: number,
  weight: string | number = 'inherit',
  line: string | number = 'normal',
  letter: string | number = 'normal'
) => {
  return css`
    font-family: ${family};
    font-weight: ${weight};
    font-size: ${px(size)};
    line-height: ${line};
    ${letter !== 'normal'
      ? css`
          letter-spacing: ${letterSpacing(size, letter)};
        `
      : ''}
  `;
};

export const fontChannel4Chadwick = (size = 16, weight: string | number = 400, line = 1.2) => {
  return font('Channel4Chadwick, sans', size, weight, line);
};

export const fontChannel4Horseferry = (size = 16, weight: string | number = 400, line = 1.1) => {
  return font('Channel4Horseferry, sans', size, weight, line);
};

export const fontWaitingfortheSunrise = (size = 16, weight: string | number = 400, line = 1.1) => {
  return font('WaitingfortheSunrise, sans', size, weight, line);
};

export const fontDMMono = (size = 16, weight: string | number = 400, line = 1.1) => {
  return font('DMMono, sans', size, weight, line);
};

export const typography14Text = css`
  ${fontChannel4Chadwick(14, 500, 1.29)}
`;

export const typography16Text = css`
  ${fontChannel4Chadwick(16, 500, 1.45)}
`;

export const typography12Caps = css`
  ${fontChannel4Chadwick(12, 500, 1.1)}
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;

export const typography14Caps = css`
  ${fontChannel4Chadwick(14, 500, 1.1)}
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const typography18Caps = css`
  ${fontChannel4Chadwick(18, 500, 1.1)}
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const typography20Caps = css`
  ${fontChannel4Horseferry(20, 700, 1.1)}
  text-transform: uppercase;
`;

export const typography24Caps = css`
  ${fontChannel4Horseferry(24, 700, 1.1)}
  letter-spacing: -0.02em;
  text-transform: uppercase;
`;
