import { IS_LIVE_STAGE } from 'config/config';
class AdobeAnalytics {
  hasCookiesConsent = true;
  initialized = false;
  pagesTracked = [];

  initialize() {
    if (this.initialized) {
      if (!IS_LIVE_STAGE()) {
        console.log('Adobe Analitycs: Already initialized');
      }
      return;
    }

    // this.hasCookiesConsent = Cookies.get('lays.cookiesConsent') === 'true';

    this.initialized = true;

    window['dataLayer'] = window['dataLayer'] || [];
  }

  trackShare(page_name, event, platform, second_level_feature = 'homepage') {
    if (!IS_LIVE_STAGE()) {
      console.log(`Adobe Analitycs: trackEvent ("${page_name}","${event}"`);
    } else {
      const data = {
        all4: {
          platform: 'channel4.com',
          top_level_feature: 'murder_island',
          second_level_feature: second_level_feature,
          authentication_status: 'registration_unavaible',
          user_guid: 'registration_unavaible',
          os_version: '',
          general_interaction_type: 'social:share_' + platform,
          page_name: 'murder_island:' + page_name,
          internal_campaign: 'footer_murder_island',
          action: {
            general_interaction: 'all4.action.general_interaction',
            social_interaction: 'all4.action.social_interaction',
          },
        },
        event: event,
      };

      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push(data);
    }
  }
  trackEvent(page_name, event, second_level_feature = 'homepage') {
    if (!IS_LIVE_STAGE()) {
      console.log(`Adobe Analitycs: trackEvent ("${page_name}","${event}"`);
    } else {
      const data = {
        all4: {
          platform: 'channel4.com',
          top_level_feature: 'murder_island',
          second_level_feature: second_level_feature,
          authentication_status: 'registration_unavaible',
          user_guid: 'registration_unavaible',
          os_version: '',
          page_name: 'murder_island:' + page_name,
          internal_campaign: 'footer_murder_island',
        },
        event: event,
      };

      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push(data);
    }
  }

  trackPage(pageName) {
    if (!IS_LIVE_STAGE()) {
      console.log(`Adobe Analitycs: Cookies disabled - event not tracked ("${pageName}", ""`);
    } else {
      const data = {
        all4: {
          platform: 'channel4.com',
          top_level_feature: 'murder_island',
          second_level_feature: pageName,
          os_version: '',
          page_name: 'murder_island:' + pageName,
          internal_campaign: 'footer_murder_island',
        },
        event: 'pageview',
      };

      window['dataLayer'] = window['dataLayer'] || [];

      window['dataLayer'].push(data);
    }
  }
}

export default new AdobeAnalytics();
