import React, { memo } from 'react';
import classNames from 'classnames';

import * as Styled from './Logo.styled';

import Svg4 from 'svgs/4.svg';

interface Props {
  className?: string;
  light?: boolean;
}

export const Logo: React.FC<Props> = ({ className, light = false }) => {
  return (
    <Styled.Wrapper className={classNames(className, { light })}>
      <Svg4 className="four" />
      <div className="bar" />
      <div className="logo" />
    </Styled.Wrapper>
  );
};

export default memo(Logo);
