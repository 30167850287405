import { Howl, Howler } from 'howler';

export default class AudioManager {
  private static _instance;
  skipOther = false;

  UISound: Howl;

  static get instance(): AudioManager {
    if (!AudioManager._instance) {
      AudioManager._instance = new AudioManager();
    }
    return AudioManager._instance;
  }

  initialize = () => {
    Howler.mute(false);
    Howler.volume(0.5);
  };

  getContext = () => {
    return Howler.ctx;
  };

  playSound(file, loop = false, skip = false, volume = 1): Howl {
    if (this.skipOther) return;
    this.skipOther = skip;
    this.UISound = new Howl({
      loop: loop,
      src: file,
      format: ['mp3'],
      volume: volume,
    });
    this.UISound.play();
    if (volume) {
      this.UISound.volume(volume);
    }
    return this.UISound;
  }

  stopSound() {
    this.skipOther = false;
    if (this.UISound) {
      this.UISound.stop();
    }
  }
}
